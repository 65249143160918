import { HELPERS } from '../classes/helpers';

// Owl full card initialize
var $owlFullCard = $(document).find('.wkb-cards__inner.full-card');
if( $owlFullCard.length > 0 ) {
  $.each($owlFullCard, function( index, slider ) {

    var $slider = $(slider);
    var $slides = $('.owl-carousel', $slider);

    if( $slides.length > 0 ) {
      var owl = $slides.owlCarousel({
        margin: 16,
        nav: true,
        dots: true,
        navText: ['', ''],
        navContainer: $slider.find('.wk-slider-nav'),
        navElement: 'div',
        autoWidth: true,
        onInitialized: HELPERS.sliderCounter,
        onChanged: HELPERS.sliderCounter,
        onResized: HELPERS.sliderCounter,
      
      });
    }
  });
}

// Owl img card initialize
var $owlImgCard = $(document).find('.wkb-cards__inner.img-card');
if( $owlImgCard.length > 0 ) {
  $.each($owlImgCard, function( index, slider ) {

    var $slider = $(slider);
    var $slides = $('.owl-carousel', $slider);

    if( $slides.length > 0 ) {
      var owl = $slides.owlCarousel({
        margin: 16,
        navElement: 'div',
        nav: true,
        navText: ['', ''],
        autoWidth: true,
        //navText: ["<div class='icon icon-arrow-slider-left icon-primary'></div>","<div class='icon icon-arrow-slider-right icon-primary'></div>"],
        navContainer: $slider.find('.wk-slider-nav'),
      });
    }
  });
}
