import { HELPERS } from '../classes/helpers';

//wkb banner slider
var $wkb_gallery = $(document).find('.wkb-gallery');
if( $wkb_gallery.length > 0 ) {
  $.each($wkb_gallery, function( index, gallery ) {
    var $gallery = $(gallery);
    var $slides = $('.owl-carousel', $gallery);
    var columns = $gallery.data('columns');
    var columns_fixed = $gallery.data('columns-fixed');
    if( $slides.length > 0 ) {
      var owl = $slides.owlCarousel({
        items: (columns_fixed ? columns : 1.7),
        nav: true,
        navText: ['', ''],
        navContainer: $gallery.find('.wk-slider-nav'),
        onInitialized: HELPERS.sliderCounter,
        onChanged: HELPERS.sliderCounter,
        onResized: HELPERS.sliderCounter,
        navElement: 'div',
        dots: true,
        loop: false,
        margin: 16,
        responsive : {
          // breakpoint from 480 up
          480 : {
            items: (columns_fixed ? columns : 2.7),
          },
          // breakpoint from 768 up
          768 : {
            items: columns,
          }
        }
      });
    }
  });
}
