import { HELPERS } from '../classes/helpers';

// tags filter
$(document).on('click', '.wkb-posts-rest .filter-tags a:not(.active)', function() { 
  $(this).closest('.filter-tags').find('a').removeClass('active');
  $(this).addClass('active');

  filterRestPosts($(this));
});

// default slider init
let $posts_slider_default = $(document).find('.wkb-posts-rest--slider .default-posts .posts');
initPostsSlider($posts_slider_default);

function filterRestPosts( $e ) {
  let $parent = $e.closest('.wkb-posts-rest');
  let $loader = $parent.find('.loader-wrap');
  let $target = $parent.find('.filter-posts');
  let $default = $parent.find('.default-posts');
  let tag = $e.data('id');
  let postsperpage = $parent.data('perpage');
  let $load_more = $target.find('[ajax-load-more-posts]');

  if(!tag) {
    $default.show();
    $target.hide();
    return;
  }

  // if data aleary exist skip ajax
  /*
  var $result = $target.find('.tag-' + tag);
  if($result.length > 0) {
    $target.show();
    $default.hide();
    $target.find('.posts').hide();
    $result.show();
    return;
  }*/

  var item_cls = '';
  if($parent.hasClass('wkb-posts-rest--grid')) { 
    item_cls = 'item col-md-4 mb-5 px-2';
  }

  $.ajax({
    url: ajax.url,
    type: 'post',
    dataType: "json",
    data: {
      action: 'filter_archive_rest',
      endpoint: $parent.data('endpoint').split(','), 
      page: 1,
      perpage: postsperpage, 
      tag: tag, 
      pager: false,
      cls: item_cls
    },
    beforeSend: function () {
      $loader.addClass('active');
    },
    success: function (data) {
      $default.hide();
      $target.show();
      $target.find('.posts').hide();
      $loader.removeClass('active');

      if(data) {
        let slider_cls = 'row';
        if($parent.hasClass('wkb-posts-rest--slider') && data.result) {
          slider_cls = 'owl-carousel';
        }

        var $element = $('<div class="posts ' + slider_cls + ' tag-' + tag + '">' + data.content + '</div>')
        $target.prepend($element);

        if($parent.hasClass('wkb-posts-rest--slider') && data.result) {
          initPostsSlider($element);
        }

        console.log(data['max_num_pages']);
        if(data['max_num_pages'] > 1) {
          $load_more.data('page', 2);
          $load_more.removeAttr('disabled');
          $load_more.show();
        } else {
          $load_more.hide();
        }
      }
    },
    error: function(xhr, ajaxOptions, thrownError) {
      $loader.removeClass('active');
      console.log(thrownError);
    }
  });
}

function initPostsSlider($posts_rest_slider) {
  
  if( $posts_rest_slider.length > 0 ) {
    $.each($posts_rest_slider, function( index, slider ) {
      var $slider = $(slider);
  
      if( $slider.length > 0 ) {
        var owl = $slider.owlCarousel({
          loop: false,
          items: 1,
          margin: 16,
          nav: true,
          navText: ['', ''],
          navContainer: $slider.parent().find('.wk-slider-nav'),
          onInitialized: HELPERS.sliderCounter,
          onChanged: HELPERS.sliderCounter,
          onResized: HELPERS.sliderCounter,
          navElement: 'div',
          dots: true,
          autoWidth: true,
          //navText: ['', ''],
          //navContainer: $(parent).find('.find-product-navigation'),
          //navElement: 'div',
          //dotsContainer: $(parent).find('.find-product-nav-dots'),
        });
      }
    });
  }
  
}

/**
 * Ajax load mora posts
 */

$(document).on('click', '.wkb-posts-rest [ajax-load-more-posts]', function() { 
  let $this = $(this);
  let $parent = $this.closest('.wkb-posts-rest');
  let tag = $parent.find('.filter-tags a.active').data('id');
  let endpoint = $parent.data('endpoint').split(',');
  let all_tags = $parent.data('tags');
  let tags = all_tags;
  let page = $this.data('page');
  let postsperpage = $parent.data('perpage');
  let item_cls = 'item col-md-4 mb-5 px-2';
  let $post_list = $parent.find('.posts:visible');

  if(typeof tag != 'undefined')
    tags = tag;

  $.ajax({
    url: ajax.url,
    type: 'post',
    dataType: "json",
    data: {
      action: 'filter_archive_rest',
      endpoint: endpoint, 
      page: page,
      perpage: postsperpage, 
      tag: tags, 
      pager: false,
      cls: item_cls
    },
    beforeSend: function () {
      //$loader.addClass('active');
      $this.attr('disabled', 'disabled');
    },
    success: function (data) {
      if(page >= data['max_num_pages']) {
        $this.hide();
      } else {
        $this.data('page', page + 1);
        $this.removeAttr('disabled');
      }

      $post_list.append(data.content);
    },
    error: function(xhr, ajaxOptions, thrownError) {
      //$loader.removeClass('active');
      $this.removeAttr('disabled');
      console.log(thrownError);
    }
  });

});


