import { HELPERS } from '../classes/helpers';
import { REST } from '../classes/rest';

$(document).on('click', '.rest-archive .pagination-rest-ajax li:not(.active) a', function() { 
  var $this = $(this);
  var page = $this.data('page');

  if(page > 1)
    HELPERS.setUrlParameter('pager', page);
  else
    HELPERS.removeUrlParameter('pager');

  REST.filterRestArchive(null, page);
});

$(document).on('click', '.rest-archive .filter-tags a:not(.active)', function() { 
  var $this = $(this);
  let calendar = false;
  let pager = true;

  $('.filter-tags a').removeClass('active');
  $this.addClass('active');

  let url = new URL(window.location.href);
  let term_url = $this.data('url') + url.search;
  history.pushState({}, "", new URL(term_url));

  if($this.closest('.rest-archive').hasClass('events-archive')) {
    calendar = true;
    pager = false;
  }
  
  REST.filterRestArchive($this.data('id'), 1, pager, calendar);
});
