export const HELPERS = {
  // Screen width breakpoints
  desktop : 1200,
  tablet : 992,
  tabletMinor : 768,
  mobile : 576,
  
  // Debounce
  debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  },

  // Get window width
  windowWidth() {
    let width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    return width;
  },

  //cookie handler
	createCookie(name, value, days) {
		var expires;
		if (days > 0) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = '; expires=' + date.toGMTString();
		} else {
			expires = '';
		}
		document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value) + expires + '; path=/';
	},

	readCookie(name) {
		var nameEQ = encodeURIComponent(name) + '=';
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
		}
		return null;
	},

	removeCookie(name) {
		this.createCookie(name, '', -1);
	},

  setUrlParameter(param, value) {
    var url = new URL(window.location.href);
    url.searchParams.set(param, value);
    
    history.pushState({}, "", url.href);
  },

  removeUrlParameter(param) {
    var url = new URL(window.location.href);

    url.searchParams.delete(param);
    history.replaceState(null, null, url);
  },

  sliderCounter(event) {
    var current = 1;
    var total = 0;
    var dots = $(event.currentTarget).find('.owl-dots button');
    var parent =  $(event.currentTarget).parent('div');

    if(event.page.count == 0) {
      total = dots.length;
    } else {
      current = event.page.index + 1;
      total = event.page.count;
    }

    if(total > 1) {
      $(parent).find('.wk-slider-nav').css('display', 'flex');
      $(parent).find('.wk-slider-nav__count').html(current  + '/' + total);
    } else {
      $(parent).find('.wk-slider-nav').hide();
    }

    dots.parent('.owl-dots').hide();
  }
}