export const REST = {
  // Debounce
  filterRestArchive(tag, page, pager = true, calendar = false) {
    let $parent = $(document).find('.rest-archive');
    let $loader = $parent.find('.loader-wrap');
    let $target = $parent.find('.archive-list');

    if(!tag) {
      tag = $parent.find('.filter-tags a.active').data('id');
    }

    if(!tag) {
      tag = $parent.data('tags');
    }

    $.ajax({
      url: ajax.url,
      type: 'post',
      dataType: "json",
      data: {
        action: 'filter_archive_rest',
        endpoint: $parent.data('endpoint'), 
        page: page,
        perpage: $parent.data('perpage'), 
        eventdatefrom: $parent.data('eventdatefrom'), 
        eventdateto: $parent.data('eventdateto'), 
        tag: tag,
        pager: pager,
        calendar: calendar
      },
      beforeSend: function () {
        $loader.addClass('active');
      },
      success: function (result) {
        $loader.removeClass('active');
        if(result) {
          if(pager) $parent.find('.archive-pager').html(result.pager);
          if(calendar) $parent.find('.calendar-wrap').html(result.calendar);

          $target.html(result.content);

          if(tag) $parent.data('tag', tag);
        }
      },
      error: function(xhr, ajaxOptions, thrownError) {
        $loader.removeClass('active');
        console.log(thrownError);
      }
    });
  }
}