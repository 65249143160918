const ics = require('ics');
import { HELPERS } from '../classes/helpers';
import { REST } from '../classes/rest';

/**
 * Handle events calendar
 */
var events_archive = $(document).find('.events-archive');

if(events_archive.length > 0) {
  var filter_day = events_archive.data('filterday');

  $(document).on('click', '.day-with-events .events-calendar__data', function() {
    var $this = $(this);
    var day = $(this).data('day');
    
    events_archive.find('.events-calendar__data').removeClass('active');
    $this.addClass('active');
 
    HELPERS.setUrlParameter('cday', day);

    filterEvents(day, day);
  });

  $(document).on('click', '.month-switcher__next', function() {
    let calendar = events_archive.find('.calendar');
    let date = calendar.data('next');

    HELPERS.setUrlParameter('cyear', date.toString().slice(0, 4));
    HELPERS.setUrlParameter('cmonth', date.toString().slice(4, 6));


    events_archive.data('eventdatefrom', date.toString().slice(0, -2) + '01');
    events_archive.data('eventdateto', calendar.data('next'));
    events_archive.data('filterdate', date.toString().slice(0, 6));
    REST.filterRestArchive(null, 1, false, true);
  });

  $(document).on('click', '.month-switcher__prev', function() {
    let calendar = events_archive.find('.calendar');
    let date = calendar.data('prev');

    HELPERS.setUrlParameter('cyear', date.toString().slice(0, 4));
    HELPERS.setUrlParameter('cmonth', date.toString().slice(4, 6));
    
    events_archive.data('eventdatefrom', date.toString().slice(0, -2) + '01');
    events_archive.data('eventdateto', date);
    events_archive.data('filterdate', date.toString().slice(0, 6));
    REST.filterRestArchive(null, 1, false, true);
  });

  $(document).on('click', '.month-switcher .month', function() {
    events_archive.find('.events-calendar__data').removeClass('active');
    $('.archive-list [data-day]').removeClass('d-none');

    filterEvents(1, 31);
  });

  filterEvents(filter_day, filter_day);
}

// iCalendar download
$(document).on('click', '.ical-download', function() {
  let single_data = $(this).data('ics');
  if(single_data) {
    generateIcs([single_data]);
  }
});

function filterEvents(day_from, day_to) {
  var events = $('.archive-list [data-datestart]');
  var filter_date = events_archive.data('filterdate');
  var filter_date_from = filter_date.toString().concat(day_from);
  var filter_date_to = filter_date.toString().concat(day_to);

  $(events).each(function() {
    let $this = $(this);
    let e_start = $this.data('datestart');
    let e_end = $this.data('dateend');

    //console.log(filter_date_from, filter_date_to);
    //console.log(e_start, e_end);

    if(
      (filter_date_from >= e_start && filter_date_to <= e_end) ||
      (e_start >= filter_date_from && e_start <= filter_date_to) ||
      (e_end >= filter_date_from && e_end <= filter_date_to)
    ) {
      $this.removeClass('d-none');
    } else {
      $this.addClass('d-none');
    }
  });
}

function generateIcs(data) {
  const { error, value } = ics.createEvents(data);

  if (error) {
    console.log('error', error);
    return;
  }

  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/calendar;charset=utf-8,' + encodeURIComponent(value));
  element.setAttribute('download', 'hit-event.ics');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}