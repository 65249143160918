import { HELPERS } from '../classes/helpers';
import AirDatepicker from 'air-datepicker';
import localeSl from 'air-datepicker/locale/sl';

// Header/reservations onscroll show/hide
var lastScrollTop = 0;
window.onscroll = function() {scrollHide()};
function scrollHide() {
  var st = document.documentElement.scrollTop;
  if (st > lastScrollTop) {
    if ($(document).scrollTop() > 200) {
      $('header').removeClass('header-down').addClass('header-up');
      if (HELPERS.windowWidth() >= 991) {
        $('.reservations').removeClass('reservations-up').addClass('reservations-down');
      }
    } 
  } else {
    $('header').removeClass('header-up').addClass('header-down');
    if ($(document).scrollTop() < 200) {
      $('header').removeClass('header-up').removeClass('header-down')
      $('.reservations').removeClass('reservations-down').addClass('reservations-up');
    }
  }
  lastScrollTop = st <= 0 ? 0 : st;
}

// Destinations modal show image on hover
$('.destinations-modal ul.sub-menu li a').hover(function () {
  $(this).addClass('active');
  var imgDestination = $('.destinations-modal ul.sub-menu li a.active img').attr('src');
  var imgTitle = $('.destinations-modal ul.sub-menu li a.active img').data('title');

  $(".destination-image").stop().fadeIn(300).html('<div class="destination-title large">'+imgTitle+'</div><img src="'+imgDestination+'">');
}, function () {
  $(this).removeClass('active');
  $(".destination-image").stop().fadeOut(300, function() {
    $(this).html('');
  });
});

// Destinations modal header padding on open
$('.destinations-modal').on('show.bs.modal', function (event) {
  $('header').css({'padding-right':''+window.innerWidth-$(window).width()+'px'})
  $('header .destinations').addClass('destinations-open');
})
$('.destinations-modal').on('hide.bs.modal', function (event) {
  $('header').css({'padding-right':'0px'});
  $('header .destinations').removeClass('destinations-open');
})

// Reservations select2
$(document).ready(function() {
  $('.reservations .dropdown').select2({
    allowClear: true,
    allowClear: false,
    minimumResultsForSearch: -1
  });
  $('.reservations .select2-selection__arrow').addClass('icon icon-arrow-down icon-black').removeClass('select2-selection__arrow');
});

// Reservations datepicker arrival
let dpArrival, dpDeparture;

dpArrival = new AirDatepicker('#phobs-arrival', {
  locale: localeSl,
  autoClose: true,
  onShow() {
    $('#phobs-arrival').closest('.phobs-datepicker').addClass('datepicker-open');
  },
  onHide() {
    $('#phobs-arrival').closest('.phobs-datepicker').removeClass('datepicker-open');
  },
  onSelect({date}) {
    dpDeparture.update({
      minDate: date
    })
  },
  position: 'top left',
  dateFormat(date) {
    return date.toLocaleString('sl', {
        year: 'numeric',
        day: '2-digit',
        month: 'short'
    });
  }
});

// Reservations datepicker departure
dpDeparture = new AirDatepicker('#phobs-departure', {
  locale: localeSl,
  autoClose: true,
  onShow() {
    $('#phobs-departure').closest('.phobs-datepicker').addClass('datepicker-open');
  },
  onHide() {
    $('#phobs-departure').closest('.phobs-datepicker').removeClass('datepicker-open');
  },
  onSelect({date}) {
    dpArrival.update({
      maxDate: date
    })
  },
  position: 'top left',
  dateFormat(date) {
    return date.toLocaleString('sl', {
        year: 'numeric',
        day: '2-digit',
        month: 'short'
    });
  }
});

$('.datepicker-arrival .icon').on('click', function() {
  dpArrival.show();
});

// Reservations mobile
if (HELPERS.windowWidth() <= 991) {
  $('.reservation-trigger .btn.btn-secondary').on('click', function() {
    $('.reservations').toggleClass('top');
    $('.reservations__inner').toggleClass('show');
    $('body').toggleClass('reservation-open');
  });
}

$('.navbar-collapse').on('show.bs.collapse', function () {
  $('body').addClass('navbar-open');
  $('header .navbar ').css({ marginTop : "0px", width : "100%" });
  $('header').css({ padding : "0px" });
  $('.navbar-toggler').css({ top : "8px", right : "25px" });
  $('#nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3)').css({ width : "34px" });
})
$('.navbar-collapse').on('shown.bs.collapse', function () {
  $(this).css({ overflow : "auto" })
})
$('.navbar-collapse').on('hidden.bs.collapse', function () {
  $('header .navbar ').css({ marginTop : "8px", width : "100%" });
  $('header').css({ padding : "0 8px" });
  $('.navbar-toggler').css({ top : "0px", right : "0px" });
  $('#nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3)').css({ width : "24px" });
  $('body').removeClass('navbar-open');
  $(this).css({ overflow : "hidden" })
})