$(document).on('click', 'body.search .pagination-ajax li:not(.active) a', function() { 
  filterSearch($(this));
});

function filterSearch( $e ) {
  let $parent = $e.closest('.search-section');
  let $loader = $parent.find('.loader-wrap');
  let target_id = $parent.data('target');
  let $target = $(target_id);

  $.ajax({
    url: ajax.url,
    type: 'post',
    dataType: "json",
    data: {
        action: 'filter_search',
        type: $parent.data('type'), 
        template: $parent.data('template'), 
        partial: $parent.data('partial'), 
        page: $e.data('page'),
        search: $parent.data('search'), 
        perpage: $parent.data('perpage'), 
    },
    beforeSend: function () {
      $loader.addClass('active');
    },
    success: function (result) {
      $loader.removeClass('active');
      if(result) {
        $parent.find('.pagination').replaceWith(result.pager);
        $target.html(result.content);
      }
    },
    error: function(xhr, ajaxOptions, thrownError) {
      console.log(thrownError);
    }
  });
}

$(document).on('click', 'body.search .pagination-rest-ajax li:not(.active) a', function() { 
  filterRestSearch($(this));
});

function filterRestSearch( $e ) {
  let $parent = $e.closest('.search-section');
  let $loader = $parent.find('.loader-wrap');
  let target_id = $parent.data('target');
  let $target = $(target_id);

  $.ajax({
    url: ajax.url,
    type: 'post',
    dataType: "json",
    data: {
        action: 'filter_search_rest',
        endpoint: $parent.data('endpoint'), 
        type: $parent.data('type'), 
        template: $parent.data('template'), 
        partial: $parent.data('partial'), 
        page: $e.data('page'),
        search: $parent.data('search'), 
        perpage: $parent.data('perpage'), 
    },
    beforeSend: function () {
      $loader.addClass('active');
    },
    success: function (result) {
      $loader.removeClass('active');
      if(result) {
        $parent.find('.pagination').replaceWith(result.pager);
        $target.html(result.content);
      }
    },
    error: function(xhr, ajaxOptions, thrownError) {
      console.log(thrownError);
    }
  });
}